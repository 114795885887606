import {
  Box,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Button as MuiButton,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseCircle } from 'iconsax-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Account, Document, DocumentType, Organization, OtterProcessingStatus, PaymentPeriod, TransactionDocumentMatch } from '../../../api';
import { Button, TreeSelect } from '../../../components';
import { UTCDatePicker } from '../../../components/utc-date-picker';
import { CURRENCY_SYMBOLS } from '../../../utils/currencies';
import { getFiscalYear } from '../../../utils/date-utils';
import { useFormState } from '../../../utils/useFormState';
import { DocumentNotesDialog } from './document-notes-dialog';

export interface DocumentEditingDialogProps {
  document: Document | null;
  matchesByDocumentId: { [documentId: string]: TransactionDocumentMatch[] };
  organization: Organization;
  accountsByFy: { [fy: string]: Account[] };
  open: boolean;
  onClose: () => void;
  onSave: (changes: Partial<Document> & { id: string }) => Promise<void>;
}

function isRequiredNumber(num: string | null) {
  if (!num) {
    return true;
  }
  return !/^-?(\d+|\d*\.\d+)$/.test(num);
}

function isNonRequiredNumber(num: string | null) {
  if (!num) {
    return false;
  }
  return !/^-?(\d+|\d*\.\d+)$/.test(num);
}

export function DocumentEditingDialog({
  open,
  onClose,
  document,
  matchesByDocumentId,
  organization,
  accountsByFy,
  onSave,
}: DocumentEditingDialogProps) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const [viewDocumentNotes, setViewNotes] = useState(false);

  const [type, setType, typeTouched, setTypeTouched] = useFormState(DocumentType.RECEIPT);

  const [beforeTax, setBeforeTax, beforeTaxTouched, setBeforeTaxTouched] = useFormState<string>('');
  const beforeTaxError = useMemo(() => {
    return isNonRequiredNumber(beforeTax);
  }, [beforeTax]);
  const [afterTax, setAfterTax, afterTaxTouched, setAfterTaxTouched] = useFormState<string>('');
  const afterTaxError = useMemo(() => {
    return isRequiredNumber(afterTax);
  }, [afterTax]);
  const [gst, setGst, gstTouched, setGstTouched] = useFormState<string>('');
  const gstError = useMemo(() => {
    return isNonRequiredNumber(gst);
  }, [gst]);
  const [hst, setHst, hstTouched, setHstTouched] = useFormState<string>('');
  const hstError = useMemo(() => {
    return isNonRequiredNumber(hst);
  }, [hst]);
  const [pst, setPst, pstTouched, setPstTouched] = useFormState<string>('');
  const pstError = useMemo(() => {
    return isNonRequiredNumber(pst);
  }, [pst]);
  const [alreadyPaid, setAlreadyPaid, alreadyPaidTouched, setAlreadyPaidTouched] = useFormState(true);
  const [paymentCardType, setPaymentCardType, paymentCardTypeTouched, setPaymentCardTypeTouched] = useFormState<string>('none');
  const [paymentAccountNumber, setPaymentAccountNumber, paymentAccountNumberTouched, setPaymentAccountNumberTouched] = useFormState('');
  const paymentAccountNumberError = useMemo(() => {
    return !!paymentAccountNumber && !/^\d+$/.test(paymentAccountNumber);
  }, [paymentAccountNumber]);
  const [paymentDate, setPaymentDate, paymentDateTouched, setPaymentDateTouched] = useFormState<Date | null>(null);
  const paymentDateError = useMemo(() => {
    if (!organization) {
      return true;
    }

    if (!alreadyPaid || !paymentAccountNumber || !paymentDate) {
      return false;
    }

    const fy = getFiscalYear(paymentDate, organization.fyEndMonth);

    return !Object.keys(accountsByFy).includes(fy);
  }, [paymentDate, accountsByFy, organization, alreadyPaid, paymentAccountNumber]);

  const [date, setDate, dateTouched, setDateTouched] = useFormState<Date | null>(null);
  const dateError = useMemo(() => {
    if (!date || !organization) {
      return true;
    }

    const parsed = new Date(date);
    if (parsed > new Date()) {
      return true;
    }

    const fy = getFiscalYear(parsed, organization.fyEndMonth);

    return !Object.keys(accountsByFy).includes(fy);
  }, [date, organization, accountsByFy]);

  const [dueDate, setDueDate, dueDateTouched, setDueDateTouched] = useFormState<Date | null>(null);

  const [currency, setCurrency, currencyTouched, setCurrencyTouched] = useFormState('');
  const [merchantName, setMerchantName, merchantNameTouched, setMerchantNameTouched] = useFormState<string>('');
  const [client, setClient, clientTouched, setClientTouched] = useFormState<string>('');

  const [isIncome, setIsIncome, isIncomeTouched, setIsIncomeTouched] = useFormState(false);

  const [reimbursement, setReimbursement, reimbursementTouched, setReimbursementTouched] = useFormState(false);
  const reimbursementError = useMemo(() => {
    return isIncome && reimbursement;
  }, [reimbursement, isIncome]);

  const eligibleAccounts = useMemo(() => {
    if (!date) {
      return [];
    }

    const fy = getFiscalYear(date, organization.fyEndMonth);

    return accountsByFy[fy] || [];
  }, [date, accountsByFy, organization]);

  const [assignedCategory, setAssignedCategory, assignedCategoryTouched, setAssignedCategoryTouched] = useFormState<string | null>(null);

  useEffect(() => {
    if (assignedCategory) {
      const assignedAccount = Object.values(accountsByFy)
        .flat()
        .find((a) => a.id === assignedCategory);

      let matchingAccount = eligibleAccounts.find((a) => a.name === assignedAccount?.name);
      if (!matchingAccount) {
        matchingAccount = eligibleAccounts.find((a) => a.standardAccount === assignedAccount?.standardAccount);
      }

      setAssignedCategory(matchingAccount?.id || '');
      setAssignedCategoryTouched(true);
    }
  }, [assignedCategory, setAssignedCategory, setAssignedCategoryTouched, eligibleAccounts, accountsByFy]);

  const [paymentObligation, setPaymentObligation, paymentObligationTouched, setPaymentObligationTouched] = useFormState(false);
  const [paymentObligationStartDate, setPaymentObligationStartDate, paymentObligationStartDateTouched, setPaymentObligationStartDateTouched] =
    useFormState<Date | null>(null);
  const paymentObligationStartDateError = useMemo(() => {
    return paymentObligation && !paymentObligationStartDate;
  }, [paymentObligationStartDate, paymentObligation]);
  const [paymentObligationPeriod, setPaymentObligationPeriod, paymentObligationPeriodTouched, setPaymentObligationPeriodTouched] =
    useFormState<PaymentPeriod | null>(PaymentPeriod.MONTHLY);
  const [paymentObligationQuantity, setPaymentObligationQuantity, paymentObligationQuantityTouched, setPaymentObligationQuantityTouched] =
    useFormState<string | null>(null);

  const typeError = useMemo(() => {
    return !alreadyPaid && type === DocumentType.RECEIPT;
  }, [alreadyPaid, type]);

  const alreadyPaidError = useMemo(() => {
    return (!alreadyPaid && type === DocumentType.RECEIPT) || (alreadyPaid && paymentObligation);
  }, [alreadyPaid, type, paymentObligation]);

  const paymentObligationError = useMemo(() => {
    return alreadyPaid && paymentObligation;
  }, [paymentObligation, alreadyPaid]);

  const error = useMemo(() => {
    if (paymentObligation && (paymentObligationStartDateError || !paymentObligationPeriod || !paymentObligationQuantity)) {
      return true;
    }

    return (
      beforeTaxError ||
      afterTaxError ||
      gstError ||
      hstError ||
      pstError ||
      dateError ||
      !currency ||
      !merchantName ||
      paymentAccountNumberError ||
      paymentDateError ||
      typeError ||
      alreadyPaidError ||
      paymentObligationError
    );
  }, [
    beforeTaxError,
    afterTaxError,
    gstError,
    hstError,
    pstError,
    dateError,
    currency,
    merchantName,
    paymentObligationPeriod,
    paymentObligationQuantity,
    paymentObligationStartDateError,
    paymentObligation,
    paymentAccountNumberError,
    paymentDateError,
    typeError,
    alreadyPaidError,
    paymentObligationError,
  ]);

  const readyToApprove = useMemo(() => {
    return !error && date && currency && assignedCategory && !typeError && !paymentObligationError;
  }, [error, date, currency, assignedCategory, typeError, paymentObligationError]);

  useEffect(() => {
    if (!document) {
      return;
    }

    setBeforeTax(document.beforeTax || '');
    setBeforeTaxTouched(false);
    setAfterTax(document.afterTax || '');
    setAfterTaxTouched(false);
    setGst(document.GST || '');
    setGstTouched(false);
    setHst(document.HST || '');
    setHstTouched(false);
    setPst(document.PST || '');
    setPstTouched(false);
    setDate(document.date);
    setDateTouched(false);
    setDueDate(document.dueDate || null);
    setDueDateTouched(false);
    setCurrency(document.currency || '');
    setMerchantName(document.merchantName || '');
    setMerchantNameTouched(false);
    setClient(document.client || '');
    setClientTouched(false);
    setType(document.type);
    setIsIncome(document.isIncome);
    setAlreadyPaid(document.alreadyPaid || document.type === DocumentType.RECEIPT);
    setAlreadyPaidTouched(false);
    setReimbursement(document.reimbursement || false);
    setReimbursementTouched(false);
    setPaymentCardType(document.paymentCardType || 'none');
    setPaymentAccountNumber(document.paymentAccountNumber || '');
    setPaymentDate(document.paymentDate || null);
    setAssignedCategory(document.assignedCategory);
    setAssignedCategoryTouched(false);
    setPaymentObligation(document.paymentObligation || false);
    setPaymentObligationStartDate(document.paymentObligationStartDate || null);
    setPaymentObligationStartDateTouched(false);
    setPaymentObligationPeriod(document.paymentObligationPeriod || PaymentPeriod.MONTHLY);
    setPaymentObligationPeriodTouched(false);
    setPaymentObligationQuantity(document.paymentObligationNumberOfPayments ? String(document.paymentObligationNumberOfPayments) : '');
    setPaymentObligationQuantityTouched(false);
  }, [
    document,
    setBeforeTax,
    setAfterTax,
    setGst,
    setHst,
    setPst,
    setDate,
    setDateTouched,
    setDueDate,
    setDueDateTouched,
    setCurrency,
    setMerchantName,
    setClient,
    setClientTouched,
    setBeforeTaxTouched,
    setAfterTaxTouched,
    setGstTouched,
    setHstTouched,
    setPstTouched,
    setMerchantNameTouched,
    setPaymentObligationStartDate,
    setPaymentObligationStartDateTouched,
    setPaymentObligationPeriod,
    setPaymentObligationPeriodTouched,
    setPaymentAccountNumberTouched,
    setPaymentObligationQuantity,
    setPaymentObligationQuantityTouched,
    setPaymentObligation,
    setAlreadyPaid,
    setAlreadyPaidTouched,
    setReimbursement,
    setReimbursementTouched,
    setPaymentCardType,
    setPaymentAccountNumber,
    setPaymentDate,
    setType,
    setTypeTouched,
    setAssignedCategory,
    setAssignedCategoryTouched,
    setIsIncome,
  ]);

  const save = useCallback(
    async ({ markAsApproved }: { markAsApproved: boolean }) => {
      if (!document || error) {
        return;
      }

      setLoading(true);
      try {
        const toSave = { id: document.id } as Partial<Document> & { id: string };

        if (beforeTaxTouched) {
          toSave.beforeTax = beforeTax;
        }

        if (afterTaxTouched) {
          toSave.afterTax = afterTax;
        }

        if (gstTouched) {
          toSave.GST = gst;
        }

        if (hstTouched) {
          toSave.HST = hst;
        }

        if (pstTouched) {
          toSave.PST = pst;
        }

        if (alreadyPaidTouched) {
          toSave.alreadyPaid = alreadyPaid;
        }

        if (reimbursementTouched) {
          toSave.reimbursement = reimbursement;
        }

        if (paymentCardTypeTouched) {
          toSave.paymentCardType = paymentCardType === 'none' ? null : paymentCardType;
        }

        if (paymentAccountNumber && paymentAccountNumberTouched) {
          toSave.paymentAccountNumber = paymentAccountNumber;
        }

        if (paymentDateTouched) {
          toSave.paymentDate = paymentDate;
        }

        if (dateTouched) {
          toSave.date = date;
        }

        if (dueDateTouched) {
          toSave.dueDate = dueDate;
        }

        if (currencyTouched) {
          toSave.currency = currency;
        }

        if (merchantNameTouched) {
          toSave.merchantName = merchantName;
        }

        if (clientTouched) {
          toSave.client = client;
        }

        if (typeTouched) {
          toSave.type = type;
        }

        if (isIncomeTouched) {
          toSave.isIncome = isIncome;
        }

        if (markAsApproved) {
          toSave.otterProcessingStatus = OtterProcessingStatus.REVIEWED;
        }

        if (assignedCategoryTouched) {
          toSave.assignedCategory = assignedCategory;
        }

        if (
          paymentObligation &&
          (paymentObligationTouched || paymentObligationQuantityTouched || paymentObligationStartDateTouched || paymentObligationPeriodTouched)
        ) {
          toSave.paymentObligation = paymentObligation;
          toSave.paymentObligationStartDate = paymentObligationStartDate;
          toSave.paymentObligationPeriod = paymentObligationPeriod;
          toSave.paymentObligationNumberOfPayments = Number(paymentObligationQuantity);
        } else if (paymentObligationTouched) {
          toSave.paymentObligation = paymentObligation;
          toSave.paymentObligationStartDate = null;
          toSave.paymentObligationPeriod = null;
          toSave.paymentObligationNumberOfPayments = null;
        }

        await onSave(toSave);
      } finally {
        setLoading(false);
      }
    },
    [
      onSave,
      document,
      beforeTax,
      beforeTaxTouched,
      afterTax,
      afterTaxTouched,
      gst,
      gstTouched,
      hst,
      hstTouched,
      pst,
      pstTouched,
      date,
      dateTouched,
      dueDate,
      dueDateTouched,
      currency,
      currencyTouched,
      merchantName,
      merchantNameTouched,
      client,
      clientTouched,
      type,
      typeTouched,
      isIncome,
      isIncomeTouched,
      assignedCategory,
      assignedCategoryTouched,
      error,
      paymentObligation,
      paymentObligationTouched,
      paymentObligationPeriod,
      paymentObligationPeriodTouched,
      paymentObligationQuantity,
      paymentObligationQuantityTouched,
      paymentObligationStartDate,
      paymentObligationStartDateTouched,
      alreadyPaid,
      alreadyPaidTouched,
      reimbursement,
      reimbursementTouched,
      paymentCardType,
      paymentCardTypeTouched,
      paymentAccountNumber,
      paymentAccountNumberTouched,
      paymentDate,
      paymentDateTouched,
    ]
  );

  if (!document) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between'>
          Edit Document
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction='row' justifyContent='stretch' height='100%' spacing={0}>
          <form style={{ flex: 0.25, paddingRight: theme.spacing(5), overflow: 'auto' }}>
            <Stack>
              <Typography variant='h4'>Parsed Fields</Typography>

              <FormControl required error={!merchantName && merchantNameTouched}>
                <TextField
                  fullWidth
                  label='Merchant (*)'
                  placeholder='Name...'
                  value={merchantName}
                  onBlur={() => setMerchantNameTouched(true)}
                  onChange={(e) => setMerchantName(e.target.value)}
                  disabled={loading}
                />
                {/* {document.merchantNameAlt?.toLowerCase() !== document.merchantName?.toLowerCase() && !document.valuesOverridden && (
                  <FormHelperText>Double-check found: {document.merchantNameAlt || '<blank>'}</FormHelperText>
                )} */}
                <FormHelperText>{!merchantName && merchantNameTouched && 'Empty merchant value'}</FormHelperText>
              </FormControl>

              <FormControl required error={!merchantName && merchantNameTouched}>
                <TextField
                  fullWidth
                  label='Client'
                  placeholder='Name...'
                  value={client}
                  onBlur={() => setClientTouched(true)}
                  onChange={(e) => setClient(e.target.value)}
                  disabled={loading}
                />
                <FormHelperText>{!client && clientTouched && 'Empty client value'}</FormHelperText>
              </FormControl>

              <FormControl error={dateError && dateTouched}>
                <UTCDatePicker
                  label='Date (UTC) (*)'
                  value={date}
                  onChange={(value) => {
                    setDate(value);
                    setDateTouched(true);
                  }}
                />
                {/* {document.dateAlt?.getTime() !== document.date?.getTime() && !document.valuesOverridden && (
                  <FormHelperText>
                    Double-check found: {document.dateAlt ? format(document.dateAlt, 'MM/dd/yyyy') : '<blank>'}
                    {document.date &&
                      document.dateAlt &&
                      format(document.date, 'MM/dd/yyyy') === format(document.dateAlt, 'MM/dd/yyyy') &&
                      ' - Minor time difference'}
                  </FormHelperText>
                )} */}
                <FormHelperText>{dateError && dateTouched && 'Invalid date'}</FormHelperText>
              </FormControl>

              <FormControl>
                <UTCDatePicker
                  label='Due Date (UTC)'
                  value={dueDate}
                  onChange={(value) => {
                    setDueDate(value);
                    setDueDateTouched(true);
                  }}
                />
              </FormControl>

              <FormControl error={gstError && gstTouched}>
                <TextField
                  fullWidth
                  label='GST'
                  placeholder='Amount...'
                  value={gst}
                  onBlur={() => setGstTouched(true)}
                  onChange={(e) => setGst(e.target.value)}
                  disabled={loading}
                />
                {/* {document.GSTAlt !== document.GST && !document.valuesOverridden && (
                  <FormHelperText>Double-check found: {document.GSTAlt || '<blank>'}</FormHelperText>
                )} */}
                <FormHelperText>{gstError && gstTouched && 'Invalid value'}</FormHelperText>
              </FormControl>

              <FormControl error={hstError && hstTouched}>
                <TextField
                  fullWidth
                  label='HST'
                  placeholder='Amount...'
                  value={hst}
                  onBlur={() => setHstTouched(true)}
                  onChange={(e) => setHst(e.target.value)}
                  disabled={loading}
                />
                {/* {document.HSTAlt !== document.HST && !document.valuesOverridden && (
                  <FormHelperText>Double-check found: {document.HSTAlt || '<blank>'}</FormHelperText>
                )} */}
                <FormHelperText>{hstError && hstTouched && 'Invalid value'}</FormHelperText>
              </FormControl>

              <FormControl error={pstError && pstTouched}>
                <TextField
                  fullWidth
                  label='PST'
                  placeholder='Amount...'
                  value={pst}
                  onBlur={() => setPstTouched(true)}
                  onChange={(e) => setPst(e.target.value)}
                  disabled={loading}
                />
                {/* {document.PSTAlt !== document.PST && !document.valuesOverridden && (
                  <FormHelperText>Double-check found: {document.PSTAlt || '<blank>'}</FormHelperText>
                )} */}
                <FormHelperText>{pstError && pstTouched && 'Invalid value'}</FormHelperText>
              </FormControl>

              <FormControl required error={beforeTaxError && beforeTaxTouched}>
                <TextField
                  fullWidth
                  label='Before Tax Amount'
                  placeholder='Amount...'
                  value={beforeTax}
                  onBlur={() => setBeforeTaxTouched(true)}
                  onChange={(e) => setBeforeTax(e.target.value)}
                  disabled={loading}
                />
                {/* {document.beforeTaxAlt !== document.beforeTax && !document.valuesOverridden && (
                  <FormHelperText>Double-check found: {document.beforeTaxAlt || '<blank>'}</FormHelperText>
                )} */}
                <FormHelperText>{beforeTaxError && beforeTaxTouched && 'Invalid value'}</FormHelperText>
              </FormControl>

              <FormControl required error={afterTaxError && afterTaxTouched}>
                <TextField
                  fullWidth
                  label='After Tax Amount (*)'
                  placeholder='Amount...'
                  value={afterTax}
                  onBlur={() => setAfterTaxTouched(true)}
                  onChange={(e) => setAfterTax(e.target.value)}
                  disabled={loading}
                />
                {/* {document.afterTaxAlt !== document.afterTax && !document.valuesOverridden && (
                  <FormHelperText>Double-check found: {document.afterTaxAlt || '<blank>'}</FormHelperText>
                )} */}
                <FormHelperText>{afterTaxError && afterTaxTouched && 'Invalid value'}</FormHelperText>
              </FormControl>

              <FormControl required error={!currency}>
                <InputLabel id='document-currency-label'>Currency</InputLabel>
                <Select
                  label='Currency (*)'
                  labelId='document-currency-label'
                  autoWidth
                  value={currency}
                  onBlur={() => setCurrencyTouched(true)}
                  onChange={(event) => setCurrency(event.target.value)}
                >
                  {CURRENCY_SYMBOLS.map((c) => (
                    <MenuItem key={c} value={c}>
                      {c}
                    </MenuItem>
                  ))}
                </Select>
                {/* {document.currencyAlt !== document.currency && !document.valuesOverridden && (
                  <FormHelperText>Double-check found: {document.currencyAlt || '<blank>'}</FormHelperText>
                )} */}
              </FormControl>

              <Divider />

              <Typography variant='h4'>Assumed Properties</Typography>

              <Stack direction='row'>
                <FormControl required error={typeError && typeTouched} style={{ flex: 1 }}>
                  <InputLabel id='document-type-label'>Document Type</InputLabel>
                  <Select
                    label='Document Type (*)'
                    labelId='document-type-label'
                    autoWidth
                    value={type}
                    onChange={(event) => {
                      setType(event.target.value as DocumentType);
                    }}
                    onBlur={() => setTypeTouched(true)}
                  >
                    <MenuItem value={DocumentType.RECEIPT}>Receipt</MenuItem>
                    <MenuItem value={DocumentType.INVOICE}>Invoice</MenuItem>
                  </Select>
                  <FormHelperText>{typeTouched && !alreadyPaid && type === DocumentType.RECEIPT && 'A receipt must be already paid.'}</FormHelperText>
                </FormControl>

                <ButtonGroup style={{ justifyContent: 'end' }} onBlur={() => setIsIncomeTouched(true)}>
                  <MuiButton variant={isIncome ? 'contained' : 'outlined'} onClick={() => setIsIncome(true)}>
                    Income
                  </MuiButton>
                  <MuiButton variant={!isIncome ? 'contained' : 'outlined'} onClick={() => setIsIncome(false)}>
                    Expense
                  </MuiButton>
                </ButtonGroup>
              </Stack>

              {!!date && !!eligibleAccounts.length && (
                <FormControl>
                  <TreeSelect
                    noSelectionValue='none'
                    label='Assigned Account (*)'
                    items={eligibleAccounts}
                    itemComparator={(a, b) => a.name.localeCompare(b.name)}
                    value={assignedCategory || 'none'}
                    onChange={(event) => {
                      setAssignedCategory(event.target.value as string);
                      setAssignedCategoryTouched(true);
                    }}
                    disabled={loading || !!matchesByDocumentId[document.id]}
                  />
                  <FormHelperText error>{assignedCategoryTouched && !assignedCategory && 'Required'}</FormHelperText>
                </FormControl>
              )}

              <FormControl error={alreadyPaidError && alreadyPaidTouched}>
                <FormControlLabel
                  control={
                    <Checkbox checked={alreadyPaid} onChange={(e) => setAlreadyPaid(e.target.checked)} onBlur={() => setAlreadyPaidTouched(true)} />
                  }
                  label='Already Paid'
                />
                <FormHelperText>
                  {alreadyPaidTouched && !alreadyPaid && type === DocumentType.RECEIPT && 'A receipt must be already paid.'}
                  {alreadyPaidTouched && alreadyPaid && paymentObligation && 'A document cannot both be already paid and have a payment obligation.'}
                </FormHelperText>
              </FormControl>

              <FormControl error={reimbursementError && (reimbursementTouched || isIncomeTouched)}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={reimbursement}
                      onChange={(e) => setReimbursement(e.target.checked)}
                      onBlur={() => setReimbursementTouched(true)}
                    />
                  }
                  label='Reimbursement'
                />
                <FormHelperText>
                  {(reimbursementTouched || isIncomeTouched) && reimbursementError && isIncome && 'An income document cannot be a reimbursement.'}
                </FormHelperText>
              </FormControl>

              {alreadyPaid && (
                <>
                  <FormControl fullWidth>
                    <InputLabel id='document-payment-card-type-label'>Card Type</InputLabel>
                    <Select
                      label='Card Type'
                      labelId='document-payment-card-type-label'
                      value={paymentCardType}
                      onBlur={() => setPaymentCardTypeTouched(true)}
                      onChange={(event) => setPaymentCardType(event.target.value)}
                    >
                      <MenuItem value='none'>None</MenuItem>
                      <MenuItem value='VISA'>Visa</MenuItem>
                      <MenuItem value='MASTERCARD'>Mastercard</MenuItem>
                      <MenuItem value='AMEX'>Amex</MenuItem>
                      <MenuItem value='BANK'>Bank</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl error={paymentAccountNumberError && paymentAccountNumberTouched}>
                    <TextField
                      fullWidth
                      label='Payment Account Number'
                      placeholder='Account #...'
                      value={paymentAccountNumber}
                      onBlur={() => setPaymentAccountNumberTouched(true)}
                      onChange={(e) => setPaymentAccountNumber(e.target.value)}
                      disabled={loading}
                    />
                    <FormHelperText>
                      {paymentAccountNumberError && paymentAccountNumberTouched && 'Invalid value. Please only enter numbers.'}
                    </FormHelperText>
                  </FormControl>

                  <FormControl error={paymentDateError && paymentDateTouched}>
                    <UTCDatePicker
                      label='Payment Date (UTC)'
                      value={paymentDate}
                      onChange={(value) => {
                        setPaymentDate(value);
                        setPaymentDateTouched(true);
                      }}
                      disabled={loading}
                    />
                    <FormHelperText>
                      {paymentAccountNumberError && paymentAccountNumberTouched && 'Invalid value. Please enter date in YYYY-MM-DD format.'}
                    </FormHelperText>
                  </FormControl>
                </>
              )}

              <FormControl error={paymentObligationError && paymentObligationTouched}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={paymentObligation}
                      onChange={(e) => setPaymentObligation(e.target.checked)}
                      onBlur={() => setPaymentObligationTouched(true)}
                    />
                  }
                  label='Payment Obligation'
                />
                <FormHelperText>
                  {paymentObligationTouched &&
                    paymentObligation &&
                    alreadyPaid &&
                    'A document cannot both be already paid and have a payment obligation.'}
                </FormHelperText>
              </FormControl>
              <Collapse in={paymentObligation}>
                <Stack>
                  <FormControl required error={paymentObligationStartDateError && paymentObligationStartDateTouched}>
                    <UTCDatePicker
                      label='Payment Start Date (UTC)'
                      value={paymentObligationStartDate}
                      onChange={(value) => {
                        setPaymentObligationStartDate(value);
                        setPaymentObligationStartDateTouched(true);
                      }}
                      disabled={loading}
                    />
                    <FormHelperText>
                      {paymentObligationStartDateError && paymentObligationStartDateTouched && 'Must be date in yyyy-MM-dd format'}
                    </FormHelperText>
                  </FormControl>

                  <FormControl required fullWidth>
                    <InputLabel id='document-payment-period-label'>Period</InputLabel>
                    <Select
                      label='Period'
                      labelId='document-payment-period-label'
                      value={paymentObligationPeriod}
                      onChange={(event) => setPaymentObligationPeriod(event.target.value as PaymentPeriod)}
                    >
                      <MenuItem value={PaymentPeriod.MONTHLY}>Monthly</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl required error={!paymentObligationQuantity && paymentObligationQuantityTouched}>
                    <TextField
                      fullWidth
                      label='Number of payments'
                      placeholder='Number of payments...'
                      value={paymentObligationQuantity}
                      onBlur={() => setPaymentObligationQuantityTouched(true)}
                      onChange={(e) => setPaymentObligationQuantity(e.target.value)}
                      disabled={loading}
                    />
                    <FormHelperText>{!paymentObligationQuantity && paymentObligationQuantityTouched && 'Invalid value'}</FormHelperText>
                  </FormControl>
                </Stack>
              </Collapse>

              <Button variant='outlined' color='primary' onClick={() => setViewNotes(true)}>
                View Notes {document.notes.length ? `(${document.notes.length})` : ''}
              </Button>
              <DocumentNotesDialog
                organizationId={organization?.id || null}
                openForDocumentId={viewDocumentNotes ? document.id : null}
                onClose={() => setViewNotes(false)}
              />
            </Stack>
          </form>

          <iframe src={document?.signedUrl} style={{ flex: 0.75 }} />
        </Stack>
      </DialogContent>
      <DialogActions>
        {loading && (
          <Box display='flex' justifyContent='center' sx={{ width: '100%' }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <>
            <Button
              variant='contained'
              color='neutral'
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
            <Button variant='outlined' color='primary' onClick={() => save({ markAsApproved: false })} disabled={error}>
              Save
            </Button>
            <Button variant='contained' color='primary' onClick={() => save({ markAsApproved: true })} disabled={error || !readyToApprove}>
              Save & Approve
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
