import { Download } from '@mui/icons-material';
import {
  Badge,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Button as MuiButton,
  Popover,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { DataGrid, GridColDef, GridRowSelectionModel, gridClasses } from '@mui/x-data-grid';
import { format } from 'date-fns';
import {
  AddCircle,
  ArrowDown2,
  ArrowLeft2,
  ArrowRight2,
  ArrowSwapHorizontal,
  BackSquare,
  Book,
  Clock,
  CloseCircle,
  Copy,
  CopySuccess,
  DocumentText1,
  Edit,
  Filter,
} from 'iconsax-react';
import { CSSProperties, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  Account,
  CreateJournalEntryArgs,
  Journal,
  JournalEntry,
  LogicalType,
  Organization,
  TopLevelAccountType,
  TrialBalance,
  useAdmin,
} from '../../../api';
import {
  AdminJournalSelect,
  AdminOrganizationSelect,
  Button,
  ConfirmDialog,
  EditAccountDialog,
  PageBody,
  PageContainer,
  PageHeader,
  Search,
  useSelectedOrganization,
} from '../../../components';
import { SortIntoTreeOrder, TreeEntity } from '../../../utils/tree';
import { DownloadFinancialStatementDialog } from './download-financial-statement-dialog';
import { JournalEntryDialog } from './journal-entry-dialog';

const useAdminData = (selectedOrganization: Organization | null, selectedJournal: Journal | null) => {
  const {
    journals,
    journalEntries,
    journalAccounts,
    organizations,
    fetchJournals,
    fetchJournalEntries,
    fetchOrganizations,
    fetchJournalAccounts,
    createJournalEntry,
    reverseJournalEntry: reverseJournalEntryApi,
    createTrialBalance,
    createJournalAccount,
    updateJournalAccount,
    deleteJournalAccount,
    downloadAccount,
    downloadAccounts,
    downloadTrialBalance: downloadTrialBalanceApi,
    downloadFinancialStatements,
    trialBalances,
  } = useAdmin();

  const sortedOrganizations = useMemo(() => {
    return organizations ? organizations.sort((a, b) => a.name.localeCompare(b.name)) : null;
  }, [organizations]);

  const journalAccountsById = useMemo(() => {
    if (!journalAccounts || !selectedJournal || !journalAccounts[selectedJournal.id]) {
      return {} as { [accountId: string]: Account };
    }

    return journalAccounts[selectedJournal.id].reduce(
      (map, current) => {
        map[current.id] = current;
        return map;
      },
      {} as { [accountId: string]: Account }
    );
  }, [journalAccounts, selectedJournal]);

  useEffect(() => {
    fetchOrganizations().catch((e) => {
      throw e;
    });
  }, [fetchOrganizations]);

  useEffect(() => {
    if (!selectedOrganization) {
      return;
    }

    fetchJournals(selectedOrganization.id!).catch((e) => {
      throw e;
    });
  }, [fetchJournals, selectedOrganization]);

  useEffect(() => {
    if (selectedJournal) {
      fetchJournalEntries(selectedJournal.id).catch((e) => {
        throw e;
      });

      fetchJournalAccounts(selectedJournal.id).catch((e) => {
        throw e;
      });

      createTrialBalance(selectedJournal.id).catch((e) => {
        throw e;
      });
    }
  }, [selectedJournal, fetchJournalEntries, fetchJournalAccounts, createTrialBalance]);

  const orgJournals = useMemo(() => {
    if (!selectedOrganization) {
      return [];
    }

    const orgJournals = journals[selectedOrganization.id!];

    return orgJournals || [];
  }, [journals, selectedOrganization]);

  const selectedJournalEntries = useMemo(() => {
    if (!selectedJournal) {
      return [];
    }

    const selectedEntries = journalEntries[selectedJournal.id];

    return selectedEntries || [];
  }, [selectedJournal, journalEntries]);

  const trialBalance = useMemo(() => {
    if (!selectedJournal) {
      return null;
    }

    const trialBalance = trialBalances[selectedJournal.id];

    return trialBalance || null;
  }, [selectedJournal, trialBalances]);

  const downloadJournalAccount = useCallback(
    async (accountId: string, options?: { onlyIncludeCurrentEntries: boolean }) => {
      if (!selectedJournal) {
        return;
      }

      await downloadAccount(selectedJournal.id, accountId, options);
    },
    [selectedJournal, downloadAccount]
  );

  const downloadJournalAccounts = useCallback(
    async (options?: { onlyIncludeCurrentEntries: boolean }) => {
      if (!selectedJournal) {
        return;
      }

      await downloadAccounts(selectedJournal.id, options);
    },
    [selectedJournal, downloadAccounts]
  );

  const createAccount = useCallback(
    async (params: {
      name: string;
      type: string;
      description: string;
      accountNumber?: string | undefined;
      parentId?: string | undefined;
      currency: string;
    }) => {
      if (!selectedJournal) {
        return;
      }

      await createJournalAccount(selectedJournal.id, params);
    },
    [selectedJournal, createJournalAccount]
  );

  const updateAccount = useCallback(
    async (updated: Partial<Account> & { id: string }) => {
      if (!selectedJournal) {
        return;
      }

      await updateJournalAccount(selectedJournal.id, updated);
    },
    [selectedJournal, updateJournalAccount]
  );

  const deleteAccount = useCallback(
    async (accountId: string) => {
      if (!selectedJournal) {
        return;
      }

      await deleteJournalAccount(selectedJournal.id, accountId);
    },
    [selectedJournal, deleteJournalAccount]
  );

  const downloadTrialBalance = useCallback(async () => {
    if (!selectedJournal) {
      return;
    }

    await downloadTrialBalanceApi(selectedJournal.id);
  }, [selectedJournal, downloadTrialBalanceApi]);

  const reverseJournalEntry = useCallback(
    async (entryId: string) => {
      if (!selectedJournal) {
        return;
      }

      await reverseJournalEntryApi(selectedJournal.id, entryId);
    },
    [reverseJournalEntryApi, selectedJournal]
  );

  return {
    journals: orgJournals,
    journalEntries: selectedJournalEntries,
    journalAccountsById,
    organizations: sortedOrganizations,
    createJournalEntry,
    reverseJournalEntry,
    createJournalAccount: createAccount,
    updateJournalAccount: updateAccount,
    deleteJournalAccount: deleteAccount,
    downloadAccount: downloadJournalAccount,
    downloadAccounts: downloadJournalAccounts,
    trialBalance,
    downloadTrialBalance,
    downloadFinancialStatements,
  };
};

const getFormattedBalance = (formatter: Intl.NumberFormat, account: Account, amount: string | number | undefined) => {
  let balance: number;

  if (typeof amount === 'string' || typeof amount === 'undefined') {
    if (!amount) {
      balance = 0;
    } else {
      balance = parseFloat(amount);
    }
  } else {
    balance = amount;
  }

  if (balance !== 0) {
    balance = [TopLevelAccountType.EQUITY, TopLevelAccountType.LIABILITIES].includes(account.topLevelType) ? balance * -1 : balance;
  }

  return formatter.format(balance);
};

function AccountTable({
  accounts,
  journal,
  searchCriteria,
  onViewAccount,
  onEditAccount,
  onDeleteAccount,
  style,
}: {
  accounts: Account[];
  journal: Journal;
  searchCriteria: string[];
  onViewAccount: (account: Account) => void;
  onEditAccount: (account: Account) => void;
  onDeleteAccount: (account: Account) => Promise<void>;
  style?: CSSProperties;
}) {
  const theme = useTheme();
  const [openRows, setOpenRows] = useState<Record<string, boolean>>({});
  const [showDeleteConfirmFor, setShowDeleteConfirmFor] = useState<Account | null>(null);

  const toggleOpenRow = useCallback((id: string) => {
    setOpenRows((existing) => ({
      ...existing,
      [id]: !existing[id],
    }));
  }, []);

  const treeRows = useMemo(() => {
    return SortIntoTreeOrder(accounts, (a, b) => a.name.localeCompare(b.name));
  }, [accounts]);

  const searchRows = useMemo(() => {
    const rows = new Set<string>();

    const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal.currency });

    for (const treeRow of treeRows) {
      for (const searchCriterion of searchCriteria) {
        if (
          searchCriterion.toLowerCase().includes(treeRow.name.toLowerCase()) ||
          treeRow.name.toLowerCase().includes(searchCriterion.toLowerCase())
        ) {
          rows.add(treeRow.id);
          for (const ancestor of treeRow.path.split('/')) {
            rows.add(ancestor);
          }
        }

        if (
          searchCriterion.toLowerCase().includes(treeRow.gifiType.toLowerCase()) ||
          treeRow.gifiType.toLowerCase().includes(searchCriterion.toLowerCase())
        ) {
          rows.add(treeRow.id);
          for (const ancestor of treeRow.path.split('/')) {
            rows.add(ancestor);
          }
        }

        const formattedBalance = getFormattedBalance(amountFormatter, treeRow, treeRow.consolidatedBalance);
        if (formattedBalance.toLowerCase().includes(searchCriterion.toLowerCase())) {
          rows.add(treeRow.id);
          for (const ancestor of treeRow.path.split('/')) {
            rows.add(ancestor);
          }
        }

        if (
          (!treeRow.consolidatedBalance && searchCriterion === '0') ||
          (treeRow.consolidatedBalance && treeRow.consolidatedBalance.toLowerCase().includes(searchCriterion.toLowerCase()))
        ) {
          rows.add(treeRow.id);
          for (const ancestor of treeRow.path.split('/')) {
            rows.add(ancestor);
          }
        }
      }
    }

    return rows;
  }, [treeRows, searchCriteria, journal.currency]);

  const rowsShowing = useMemo(() => {
    const rowsToShow = new Set<string>();
    for (const treeRow of treeRows) {
      if (!treeRow.parentId && !searchCriteria.length) {
        rowsToShow.add(treeRow.id);
      } else if (searchRows.has(treeRow.id)) {
        rowsToShow.add(treeRow.id);
      } else {
        let ancestorsOpen = true;
        for (const ancestor of treeRow.path.split('/')) {
          if (!openRows[ancestor]) {
            ancestorsOpen = false;
          }
        }
        if (ancestorsOpen) {
          rowsToShow.add(treeRow.id);
        }
      }
    }

    return treeRows.filter((r) => rowsToShow.has(r.id));
  }, [treeRows, openRows, searchRows, searchCriteria]);

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'treeToggle',
        headerName: '',
        width: 64,
        sortable: false,
        renderCell: (params) => {
          const account = params.row as Account & TreeEntity;
          if (account.hasChildren) {
            return (
              <IconButton disabled={searchRows.has(account.id)} onClick={() => toggleOpenRow(account.id)} sx={{ marginRight: 8 }}>
                {(openRows[account.id] || searchRows.has(account.id)) && <ArrowDown2 />}
                {!openRows[account.id] && !searchRows.has(account.id) && <ArrowRight2 />}
              </IconButton>
            );
          }
        },
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          const account = params.row as Account & TreeEntity;
          return <span style={{ paddingLeft: `calc(${theme.spacing(8)} * ${account.depth})` }}>{account.name}</span>;
        },
      },
      {
        field: 'gifiType',
        headerName: 'GIFI',
        sortable: false,
        flex: 1,
      },
      {
        field: 'balance',
        headerName: 'Balance',
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          const account = params.row as Account;
          const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal.currency });

          return openRows[account.id] ? '' : getFormattedBalance(amountFormatter, account, account.consolidatedBalance);
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        filterable: false,
        width: 150,
        align: 'center',
        renderCell: (params) => {
          const account = params.row as Account;
          const canDeleteAccount = account.parentId && !parseFloat(account.standaloneBalance) && account.logicalType !== LogicalType.GENERAL_ACCOUNT;

          return (
            <Stack direction='row' spacing={1}>
              <Tooltip title='View Account'>
                <span>
                  <IconButton onClick={() => onViewAccount(account)}>
                    <Book size='1rem' variant='Bold' color={theme.palette.primary.main} />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title='Edit Account'>
                <span>
                  <IconButton onClick={() => onEditAccount(account)}>
                    <Edit variant='Bold' size='1rem' color={theme.palette.primary.main} />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title='Delete Account'>
                <span>
                  <IconButton disabled={!canDeleteAccount} onClick={() => setShowDeleteConfirmFor(account)}>
                    <CloseCircle size='1rem' variant='Bold' color={canDeleteAccount ? theme.palette.warning.main : theme.palette.text.disabled} />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          );
        },
      },
    ];
  }, [openRows, journal, onViewAccount, toggleOpenRow, theme, searchRows, onEditAccount]);

  return (
    <>
      <DataGrid
        style={style}
        rows={rowsShowing}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        pageSizeOptions={[50]}
      />
      <ConfirmDialog
        open={!!showDeleteConfirmFor}
        onClose={() => setShowDeleteConfirmFor(null)}
        onConfirm={async () => {
          await onDeleteAccount(showDeleteConfirmFor!);
          setShowDeleteConfirmFor(null);
        }}
        message='Are you sure you want to delete this account?'
      />
    </>
  );
}

interface JournalEntryTableProps {
  journal: Journal;
  journalEntries: JournalEntry[];
  journalAccountsById: { [accountId: string]: Account };
  account?: Account;
  onViewDocumentForJournalEntry: (journalEntry: JournalEntry) => void;
  onViewTransactionForJournalEntry: (journalEntry: JournalEntry) => void;
  onSelectJournalEntry: (journalEntry: JournalEntry) => void;
  onReverseJournalEntry: (journalEntry: JournalEntry) => Promise<void>;
  style?: CSSProperties;
}
function JournalEntryTable({
  journal,
  journalEntries,
  journalAccountsById,
  account,
  onViewTransactionForJournalEntry,
  onViewDocumentForJournalEntry,
  onSelectJournalEntry,
  onReverseJournalEntry,
  style,
}: JournalEntryTableProps) {
  const PAGE_SIZE = 50;
  const theme = useTheme();
  const [journalEntryDetails, setJournalEntryDetails] = useState<JournalEntry | null>(null);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const [loadingEntries, setLoadingEntries] = useState(new Set());

  const [confirmEntryReversalFor, setConfirmEntryReversalFor] = useState<JournalEntry | null>(null);
  const reverseEntry = useCallback(
    async (entry: JournalEntry) => {
      try {
        setLoadingEntries((current) => {
          const newSet = new Set(current);
          newSet.add(entry.id);
          return newSet;
        });

        await onReverseJournalEntry(entry);
      } finally {
        setLoadingEntries((current) => {
          const newSet = new Set(current);
          newSet.delete(entry.id);
          return newSet;
        });
      }
    },
    [onReverseJournalEntry]
  );

  const journalEntryTotals = useMemo(() => {
    const totals = {} as {
      [journalEntryId: string]: {
        debit: number;
        credit: number;
        net: number;
        originalNet: number | undefined;
        runningNet: number;
        runningOriginalNet: number | undefined;
      };
    };

    const orderedJournalEntries = journalEntries.sort((a, b) => {
      const dateDifference = a.date.getTime() - b.date.getTime();
      if (dateDifference === 0) {
        return a.index - b.index;
      }
      return dateDifference;
    });

    let runningTotal = 0;
    let runningOriginalTotal: number | undefined = 0;

    for (const entry of orderedJournalEntries) {
      let debitTotal = 0;
      let creditTotal = 0;
      let debitOriginalTotal = 0;
      let creditOriginalTotal = 0;

      let unknownOriginalAmount = false;
      for (const debit of entry.debits) {
        if (!account || debit.accountId === account.id) {
          debitTotal += parseFloat(debit.amount);

          if (account && account.externalCurrency !== debit.foreignCurrency) {
            unknownOriginalAmount = true;
          }

          if (debit.foreignCurrency) {
            debitOriginalTotal += parseFloat(debit.foreignCurrencyAmount!);
          }
        }
      }

      for (const credit of entry.credits) {
        if (!account || credit.accountId === account.id) {
          creditTotal += parseFloat(credit.amount);

          if (account && account.externalCurrency !== credit.foreignCurrency) {
            unknownOriginalAmount = true;
          }

          if (credit.foreignCurrency) {
            creditOriginalTotal += parseFloat(credit.foreignCurrencyAmount!);
          }
        }
      }

      const net = debitTotal - creditTotal;
      const originalNet = unknownOriginalAmount ? undefined : debitOriginalTotal - creditOriginalTotal;

      runningTotal += net;
      runningOriginalTotal = runningOriginalTotal !== undefined && !unknownOriginalAmount ? runningOriginalTotal + originalNet! : undefined;

      totals[entry.id] = {
        debit: debitTotal,
        credit: creditTotal,
        net,
        originalNet,
        runningNet: runningTotal,
        runningOriginalNet: runningOriginalTotal,
      };
    }

    return totals;
  }, [journalEntries, account]);

  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (journalEntryId: string) => {
    setIdCopied(journalEntryId);
    await navigator.clipboard.writeText(journalEntryId);
  }, []);

  const columns: GridColDef[] = useMemo(() => {
    const runningTotalColumns: GridColDef[] = [];
    if (account) {
      runningTotalColumns.push(
        {
          field: 'delta',
          headerName: 'Delta',
          headerAlign: 'right',
          align: 'right',
          minWidth: 140,
          renderCell: (params) => {
            const entry = params.row as JournalEntry;
            if (!journalEntryTotals[entry.id]) {
              return null;
            }

            const jeNet = journalEntryTotals[entry.id].net;
            const positive =
              jeNet === 0 ||
              (jeNet > 0 && [TopLevelAccountType.ASSETS, TopLevelAccountType.EXPENSES, TopLevelAccountType.REVENUE].includes(account.topLevelType)) ||
              (jeNet < 0 && [TopLevelAccountType.LIABILITIES, TopLevelAccountType.EQUITY].includes(account.topLevelType));
            const deltaColor = positive ? theme.palette.text.primary : theme.palette.error.main;

            const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal.currency });

            if (account && account.externalCurrency && account.externalCurrency !== journal.currency) {
              const originalAmountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account.externalCurrency });

              const jeOriginalNet = journalEntryTotals[entry.id].originalNet;

              return (
                <Stack spacing={0}>
                  <Typography color={deltaColor} align='right'>{`${positive ? '+' : ''}${getFormattedBalance(
                    amountFormatter,
                    account,
                    jeNet
                  )}`}</Typography>
                  <Typography color={deltaColor} align='right'>{`(${
                    jeOriginalNet ? getFormattedBalance(originalAmountFormatter, account, jeOriginalNet) : 'Unknown Original Amount'
                  })`}</Typography>
                </Stack>
              );
            } else {
              return (
                <Typography color={deltaColor} align='right'>{`${positive ? '+' : ''}${getFormattedBalance(
                  amountFormatter,
                  account,
                  jeNet
                )}`}</Typography>
              );
            }
          },
        },
        {
          field: 'runningTotal',
          headerName: 'Running Total',
          headerAlign: 'right',
          align: 'right',
          minWidth: 140,
          renderCell: (params) => {
            const entry = params.row as JournalEntry;
            if (!journalEntryTotals[entry.id]) {
              return null;
            }

            const rt = journalEntryTotals[entry.id].runningNet;
            const positive =
              rt === 0 ||
              (rt > 0 && [TopLevelAccountType.ASSETS, TopLevelAccountType.EXPENSES, TopLevelAccountType.REVENUE].includes(account.topLevelType)) ||
              (rt < 0 && [TopLevelAccountType.LIABILITIES, TopLevelAccountType.EQUITY].includes(account.topLevelType));
            const totalColor = positive ? theme.palette.text.primary : theme.palette.error.main;

            const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal.currency });

            if (account && account.externalCurrency && account.externalCurrency !== journal.currency) {
              const originalAmountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account.externalCurrency });

              const rtOriginal = journalEntryTotals[entry.id].runningOriginalNet;

              return (
                <Stack spacing={0}>
                  <Typography color={totalColor} align='right'>
                    {getFormattedBalance(amountFormatter, account, rt)}
                  </Typography>
                  <Typography color={totalColor} align='right'>{`(${
                    rtOriginal ? getFormattedBalance(originalAmountFormatter, account, rtOriginal) : 'Unknown Original Amount'
                  })`}</Typography>
                </Stack>
              );
            } else {
              return <Typography color={totalColor} align='right'>{`${getFormattedBalance(amountFormatter, account, rt)}`}</Typography>;
            }
          },
        }
      );
    }

    const columns: GridColDef[] = [
      {
        field: 'id',
        headerName: 'ID',
        width: 50,
        renderCell: (params) => {
          const je = params.row as JournalEntry;

          return (
            <Tooltip title={je.id}>
              <span>
                <IconButton onClick={() => copy(je.id)}>
                  {idCopied === je.id ? (
                    <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                  ) : (
                    <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          );
        },
      },
      {
        field: 'date',
        headerName: 'Date',
        sortComparator: (_v1, _v2, param1, param2) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          const dateA = param1.api.getRow(param1.id).date as Date;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          const dateB = param2.api.getRow(param2.id).date as Date;

          const dateDifference = dateA.getTime() - dateB.getTime();
          if (dateDifference === 0) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            const indexA = param1.api.getRow(param1.id).index as number;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            const indexB = param2.api.getRow(param2.id).index as number;

            return indexA - indexB;
          }

          return dateDifference;
        },
        renderCell: (params) => {
          const je = params.row as JournalEntry;
          return format(je.date, 'MMM d, yyyy');
        },
      },
      {
        field: 'memo',
        headerName: 'Memo',
        flex: 1,
        renderCell: (params) => {
          const je = params.row as JournalEntry;

          return <span style={{ whiteSpace: 'pre-line' }}>{je.memo}</span>;
        },
      },
      {
        field: 'total',
        headerName: 'Total',
        headerAlign: 'right',
        align: 'right',
        minWidth: 140,
        renderCell: (params) => {
          const je = params.row as JournalEntry;
          if (!journalEntryTotals[je.id]) {
            return null;
          }

          const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal.currency });

          const total = journalEntryTotals[je.id].credit;

          const formattedAmount = amountFormatter.format(total);

          let unknownOriginalAmount = false;
          if (account && account.externalCurrency && account.externalCurrency !== journal.currency) {
            const originalAmountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account.externalCurrency });

            let originalAmount = 0;
            for (const credit of je.credits) {
              if (credit.foreignCurrency !== account.externalCurrency) {
                unknownOriginalAmount = true;
                break;
              }

              originalAmount += parseFloat(credit.foreignCurrencyAmount!);
            }

            const originalFormattedAmount = originalAmountFormatter.format(originalAmount);

            return (
              <Stack spacing={0}>
                <Typography align='right'>{formattedAmount}</Typography>
                <Typography align='right'>{`(${unknownOriginalAmount ? 'Unknown Original Amount' : originalFormattedAmount})`}</Typography>
              </Stack>
            );
          } else {
            return <Typography align='right'>{formattedAmount}</Typography>;
          }
        },
      },
      ...runningTotalColumns,
      {
        field: 'actions',
        headerName: 'Actions',
        width: 160,
        renderCell: (params) => {
          const entry = params.row as JournalEntry;

          const isTransactionEntry = entry.tags.find((t) => t.startsWith('transaction::'));
          const isDocumentEntry = entry.tags.find((t) => t.startsWith('document::'));
          const isOpeningBalanceEntry = entry.tags.find((t) => t.startsWith('opening-balances::'));
          const isManualEntry = entry.tags.length === 0;

          const reverseAllowed = isManualEntry || isOpeningBalanceEntry;

          return (
            <Stack direction='row' spacing={1}>
              <Tooltip title='Reverse Entry'>
                <span>
                  <IconButton
                    disabled={loadingEntries.has(entry.id) || !reverseAllowed}
                    onClick={() => {
                      setConfirmEntryReversalFor(entry);
                    }}
                  >
                    <BackSquare
                      size='1rem'
                      variant='Bold'
                      color={loadingEntries.has(entry.id) || !reverseAllowed ? theme.palette.text.disabled : theme.palette.primary.main}
                    />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title='View Journal Entry Details'>
                <span>
                  <IconButton
                    onClick={() => {
                      setJournalEntryDetails(entry);
                    }}
                  >
                    <Book size='1rem' variant='Bold' color={theme.palette.primary.main} />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title='View Transaction'>
                <span>
                  <IconButton
                    disabled={!isTransactionEntry}
                    onClick={() => {
                      setTimeout(() => {
                        onViewTransactionForJournalEntry(entry);
                      }, 100);
                    }}
                  >
                    <ArrowSwapHorizontal
                      size='1rem'
                      variant='Bold'
                      color={isTransactionEntry ? theme.palette.primary.main : theme.palette.text.disabled}
                    />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title='View Document'>
                <span>
                  <IconButton
                    disabled={!isDocumentEntry}
                    onClick={() => {
                      setTimeout(() => {
                        onViewDocumentForJournalEntry(entry);
                      }, 100);
                    }}
                  >
                    <DocumentText1 size='1rem' variant='Bold' color={isDocumentEntry ? theme.palette.primary.main : theme.palette.text.disabled} />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          );
        },
      },
    ];

    return columns;
  }, [
    journal.currency,
    journalEntryTotals,
    onViewTransactionForJournalEntry,
    theme,
    copy,
    idCopied,
    account,
    loadingEntries,
    onViewDocumentForJournalEntry,
  ]);

  return (
    <>
      <DataGrid
        getRowHeight={() => 'auto'}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
        style={style}
        disableVirtualization={true}
        rows={journalEntries}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onRowClick={(row) => onSelectJournalEntry(row.row as JournalEntry)}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(rows) => setSelectedRows(rows)}
        pageSizeOptions={[50]}
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
        }}
      />
      <JournalEntryDialog
        existingDetails={journalEntryDetails || undefined}
        open={!!journalEntryDetails}
        onClose={() => setJournalEntryDetails(null)}
        journal={journal}
        journalAccountsById={journalAccountsById}
        loading={false}
        readOnly={true}
        onCreate={() => {
          throw new Error('Trying to create entry in view mode');
        }}
      />
      <ConfirmDialog
        open={!!confirmEntryReversalFor}
        onClose={() => setConfirmEntryReversalFor(null)}
        onConfirm={async () => {
          setConfirmEntryReversalFor(null);
          await reverseEntry(confirmEntryReversalFor!);
        }}
        message='Are you sure you want to reverse this journal entry?'
      />
    </>
  );
}

interface AccountsListProps {
  journal: Journal;
  journalEntries: JournalEntry[];
  journalAccountsById: { [accountId: string]: Account };
  onViewAccount: (account: Account) => void;
  onUpdateAccount: (account: Partial<Account> & { id: string }) => Promise<void>;
  onCreateAccount: (createAccountParams: {
    name: string;
    type: string;
    description: string;
    accountNumber?: string;
    parentId?: string;
    currency: string;
  }) => Promise<void>;
  onDeleteAccount: (accountId: string) => Promise<void>;
  onDownloadAccounts: (params: { onlyIncludeCurrentEntries: boolean }) => void;
  style?: CSSProperties;
}
function AccountsList({
  journal,
  journalEntries,
  journalAccountsById,
  onViewAccount,
  onUpdateAccount,
  onCreateAccount,
  onDeleteAccount,
  onDownloadAccounts,
  style,
}: AccountsListProps) {
  const [searchCriteria, setSearchCriteria] = useState<string[]>([]);
  const [showEditAccountModal, setShowEditAccountModal] = useState(false);
  const [editAccount, setEditAccount] = useState<Account | null>(null);
  const [downloadOnlyIncludesCurrentEntries, setDownloadOnlyIncludesCurrentEntries] = useState(false);
  const [downloadMenuOpen, setDownloadMenuOpen] = useState(false);
  const downloadButtonRef = useRef<HTMLButtonElement | null>(null);
  const theme = useTheme();

  const openAccountModal = (account?: Account) => {
    setEditAccount(account || null);
    setShowEditAccountModal(true);
  };

  const createAccount = async (createAccountParams: {
    name: string;
    type: string;
    description: string;
    accountNumber?: string;
    parentId?: string;
    currency: string;
  }) => {
    await onCreateAccount(createAccountParams);
    setShowEditAccountModal(false);
  };

  const updateAccount = async (account: Partial<Account> & { id: string }) => {
    await onUpdateAccount(account);
    setShowEditAccountModal(false);
    setEditAccount(null);
  };

  return (
    <Stack style={style}>
      <Stack direction='row'>
        <Search style={{ flex: 1 }} searchCriteria={searchCriteria} onSearchCriteriaUpdate={(criteria) => setSearchCriteria(criteria)} />

        <Button variant='contained' color='primary' onClick={() => openAccountModal()}>
          <AddCircle size='1rem' variant='Bold' style={{ marginRight: theme.spacing(2) }} />
          Create Account
        </Button>

        <Button variant='outlined' color='neutral' style={{ background: 'none' }} onClick={() => setDownloadMenuOpen(true)} ref={downloadButtonRef}>
          <Download />
        </Button>
        <Popover
          open={downloadMenuOpen}
          onClose={() => setDownloadMenuOpen(false)}
          anchorEl={downloadButtonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <MenuList>
            <MenuItem
              onClick={() => {
                setDownloadOnlyIncludesCurrentEntries((current) => !current);
              }}
            >
              <FormControlLabel
                onClick={(e) => {
                  e.preventDefault();
                }}
                control={
                  <Checkbox checked={downloadOnlyIncludesCurrentEntries} onChange={(e) => setDownloadOnlyIncludesCurrentEntries(e.target.checked)} />
                }
                label='Only Include Current Entries'
              />
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                onDownloadAccounts({
                  onlyIncludeCurrentEntries: downloadOnlyIncludesCurrentEntries,
                });
              }}
            >
              <ListItemText>Download</ListItemText>
            </MenuItem>
          </MenuList>
        </Popover>
      </Stack>
      <EditAccountDialog
        journal={journal}
        journalEntries={journalEntries}
        open={showEditAccountModal}
        onClose={() => setShowEditAccountModal(false)}
        createAccount={createAccount}
        updateAccount={updateAccount}
        accounts={Object.values(journalAccountsById)}
        existingAccount={editAccount}
      />

      <AccountTable
        journal={journal}
        accounts={Object.values(journalAccountsById)}
        searchCriteria={searchCriteria}
        onViewAccount={onViewAccount}
        onEditAccount={openAccountModal}
        onDeleteAccount={(account) => onDeleteAccount(account.id)}
      />
    </Stack>
  );
}

interface UseFilteredJournalEntriesArgs {
  journalEntries: JournalEntry[];
  account?: Account;
  searchCriteria: string[];
  filterConfig?: { onlyShowCurrentEntries: boolean };
  journalAccountsById: { [accountId: string]: Account };
}
const useFilteredJournalEntries = ({ journalEntries, account, filterConfig, searchCriteria }: UseFilteredJournalEntriesArgs) => {
  return useMemo(() => {
    const freeformFilter = (searchCriterion: string, e: JournalEntry) => {
      if (e.id === searchCriterion) {
        return true;
      }

      const eAmount = [...e.debits, ...e.credits]
        .filter((l) => (account ? l.accountId === account.id : true))
        .map((d) => parseFloat(d.amount))
        .reduce((sum, current) => {
          return sum + current;
        }, 0);

      const searchAmount = parseFloat(searchCriterion);
      if (String(eAmount).startsWith(String(searchAmount)) || String(searchAmount).startsWith(String(eAmount))) {
        return true;
      }

      const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: e.debits[0].foreignCurrency || 'CAD' });
      const formattedAmount = amountFormatter.format(eAmount);
      if (formattedAmount.includes(searchCriterion) || searchCriterion.includes(formattedAmount)) {
        return true;
      }

      const formattedDate = format(e.date, 'MMM d, yyyy');
      if (formattedDate.includes(searchCriterion) || searchCriterion.includes(formattedDate)) {
        return true;
      }

      if (e.memo?.toLowerCase().includes(searchCriterion.toLowerCase()) || (e.memo && searchCriterion.toLowerCase().includes(e.memo))) {
        return true;
      }

      return false;
    };

    const idFilter = (searchCriterion: string, e: JournalEntry) => {
      if (e.id === searchCriterion) {
        return true;
      }

      return false;
    };

    return journalEntries.filter((e) => {
      if (
        filterConfig?.onlyShowCurrentEntries &&
        e.tags.length &&
        !e.tags.find(
          (t) =>
            t !== 'reversal' &&
            t !== 'reversed' &&
            !t.startsWith('reversal::') &&
            !t.startsWith('document_reversal::') &&
            !t.startsWith('transaction::') &&
            !t.startsWith('document::') &&
            !t.startsWith('old-opening-balances::')
        )
      ) {
        return false;
      }

      if (!searchCriteria.length) {
        return true;
      }

      for (const searchCriterion of searchCriteria) {
        if (!searchCriterion) {
          continue;
        }

        if (searchCriterion.startsWith('id::')) {
          const result = idFilter(searchCriterion.slice('id::'.length), e);
          if (result) {
            return true;
          }
        } else if (searchCriterion.startsWith('tid::')) {
          const transactionIdParts = searchCriterion.split('::');
          if (transactionIdParts.length === 2 && transactionIdParts[1] && e.tags.find((t) => t.startsWith(`transaction::${transactionIdParts[1]}`))) {
            return true;
          }
        } else if (searchCriterion.startsWith('did::')) {
          const documentIdParts = searchCriterion.split('::');
          if (documentIdParts.length === 2 && documentIdParts[1] && e.tags.find((t) => t.startsWith(`document::${documentIdParts[1]}`))) {
            return true;
          }
        } else {
          const result = freeformFilter(searchCriterion, e);
          if (result) {
            return true;
          }
        }
      }

      return false;
    });
  }, [journalEntries, searchCriteria, account, filterConfig]);
};

const DetailsHeader = styled.header`
  flex: 0;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  align-items: center;
`;

const BalanceTable = styled.table`
  td {
    text-align: right;
  }

  .button-column {
    width: 1rem;
  }
`;

const HistoricalBalanceTable = styled.table`
  border-collapse: collapse;

  tr {
    cursor: pointer;
  }

  tr:hover {
    > td {
      background: ${({ theme }) => alpha(theme.palette.primary.main, 0.15)};
    }
  }

  td {
    padding: ${({ theme }) => theme.spacing(2)};
  }
`;

interface BalanceComparisonProps {
  journal: Journal;
  account: Account;
}
function BalanceComparison({ journal, account }: BalanceComparisonProps) {
  const theme = useTheme();
  const { fetchJournalAccountBalanceHistory, journalAccountHistoricalBalances } = useAdmin();

  useEffect(() => {
    if (!journal || !account) {
      return;
    }

    fetchJournalAccountBalanceHistory(journal.id, account.id).catch((e) => {
      throw e;
    });
  }, [journal, account, fetchJournalAccountBalanceHistory]);

  const journalBalance = useMemo(() => {
    const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal.currency || 'CAD' });
    return getFormattedBalance(amountFormatter, account, account.consolidatedBalance);
  }, [account, journal]);

  const actualBalances = useMemo(() => {
    if (!journalAccountHistoricalBalances[account.id]) {
      return null;
    }

    const balances = journalAccountHistoricalBalances[account.id].sort((a, b) => b.date.getTime() - a.date.getTime());

    const journalAmountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal.currency || 'CAD' });
    const externalAmountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account.externalCurrency || 'CAD' });

    return balances
      .map((b) => ({
        id: b.id,
        date: b.date,
        balance: externalAmountFormatter.format(parseFloat(b.balance)),
        convertedBalance: journalAmountFormatter.format(parseFloat(b.convertedBalance)),
      }))
      .sort((a, b) => a.date.getTime() - b.date.getTime());
  }, [account, journalAccountHistoricalBalances, journal]);

  const [selectedActualBalance, setSelectedActualBalance] = useState<{
    id: string;
    date: Date;
    balance: string;
    convertedBalance: string;
  } | null>(null);

  useEffect(() => {
    if (actualBalances) {
      setSelectedActualBalance(actualBalances[0]);
    }
  }, [actualBalances]);

  const [balanceSelectDialogOpen, setBalanceSelectDialogOpen] = useState(false);

  if (!actualBalances || !selectedActualBalance) {
    return null;
  }

  return (
    <>
      <BalanceTable>
        <tbody>
          <tr>
            <td>
              <Typography textAlign='right'>Journal Balance: {journalBalance}</Typography>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <Tooltip title={format(selectedActualBalance.date, 'MMM d, yyyy')}>
                <Typography textAlign='right'>
                  <span>Actual Balance</span>
                  <span>{selectedActualBalance !== actualBalances[0] ? ` (${format(selectedActualBalance.date, 'MMM d, yyyy')})` : ''}:</span>
                  <span>
                    {' '}
                    {selectedActualBalance.balance}{' '}
                    {journal.currency !== account.externalCurrency ? `(${selectedActualBalance.convertedBalance})` : ''}
                  </span>
                </Typography>
              </Tooltip>
            </td>
            <td className='button-column'>
              <IconButton onClick={() => setBalanceSelectDialogOpen(true)}>
                <Clock size='1rem' color={theme.palette.primary.main} />
              </IconButton>
            </td>
          </tr>
        </tbody>
      </BalanceTable>
      <Dialog open={balanceSelectDialogOpen} onClose={() => setBalanceSelectDialogOpen(false)}>
        <DialogTitle>
          <Stack direction='row' justifyContent='space-between'>
            <span>Choose Historical Balance</span>
            <IconButton onClick={() => setBalanceSelectDialogOpen(false)}>
              <CloseCircle />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <HistoricalBalanceTable
            style={{
              width: '100%',
            }}
          >
            <tbody>
              {actualBalances.map((b) => (
                <tr
                  onClick={() => {
                    setSelectedActualBalance(b);
                    setBalanceSelectDialogOpen(false);
                  }}
                >
                  <td>{format(b.date, 'MMM d, yyyy')}</td>
                  <td
                    style={{
                      textAlign: 'right',
                    }}
                  >
                    {b.balance} {journal.currency !== account.externalCurrency ? ` (${b.convertedBalance})` : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </HistoricalBalanceTable>
        </DialogContent>
      </Dialog>
    </>
  );
}

interface AccountDetailsProps {
  account: Account;
  journal: Journal;
  journalEntries: JournalEntry[];
  journalAccountsById: { [accountId: string]: Account };
  onBack: () => void;
  onViewTransactionForJournalEntry: (journalEntry: JournalEntry) => void;
  onViewDocumentForJournalEntry: (journalEntry: JournalEntry) => void;
  onReverseJournalEntry: (journalEntry: JournalEntry) => Promise<void>;
  createJournalEntry: (entry: CreateJournalEntryArgs) => Promise<void>;
  onDownloadAccount: (params: { onlyIncludeCurrentEntries: boolean }) => Promise<void>;
  style?: CSSProperties;
}
function AccountDetails({
  account,
  journal,
  journalEntries,
  journalAccountsById,
  createJournalEntry,
  onBack,
  onViewTransactionForJournalEntry,
  onViewDocumentForJournalEntry,
  onReverseJournalEntry,
  onDownloadAccount,
  style,
}: AccountDetailsProps) {
  const [searchCriteria, setSearchCriteria] = useState<string[]>([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [downloadMenuOpen, setDownloadMenuOpen] = useState(false);
  const [downloadOnlyIncludesCurrentEntries, setDownloadOnlyIncludesCurrentEntries] = useState(false);
  const downloadButtonRef = useRef<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const { journalEntryIds, updateJournalEntryIds } = useContext(AdminJournalsPageUrlContext);

  const [filterConfig, setFilterConfig] = useState({
    onlyShowCurrentEntries: true,
  });
  const appliedFilterCount = useMemo(() => {
    let count = 0;
    if (!filterConfig.onlyShowCurrentEntries) {
      count++;
    }
    return count;
  }, [filterConfig]);
  const [showFilterConfigMenu, setShowFilterConfigMenu] = useState(false);
  const filterConfigButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (journalEntryIds.length) {
      setSearchCriteria(journalEntryIds.map((eid) => `id::${eid}`));
    }
  }, [journalEntryIds]);

  const searchFilteredEntries = useFilteredJournalEntries({
    journalEntries,
    account,
    searchCriteria,
    filterConfig,
    journalAccountsById,
  });

  const filteredEntries = useMemo(() => {
    return searchFilteredEntries.filter((e) => {
      const debit = e.debits.find((l) => l.accountId === account.id);
      if (debit) {
        return true;
      }

      const credit = e.credits.find((l) => l.accountId === account.id);
      if (credit) {
        return true;
      }

      return false;
    });
  }, [searchFilteredEntries, account]);

  const [loading, setLoading] = useState(false);
  const createNewEntry = async (entry: CreateJournalEntryArgs) => {
    try {
      setLoading(true);
      await createJournalEntry(entry);
      setCreateDialogOpen(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack style={style}>
      <DetailsHeader>
        <Box>
          <MuiButton
            sx={{
              width: 'auto',
            }}
            variant='text'
            onClick={onBack}
          >
            <ArrowLeft2 variant='Bold' size='1rem' />
            Back to Accounts
          </MuiButton>
        </Box>

        <Typography variant='h4' textAlign='center'>
          {account.name}
        </Typography>

        <BalanceComparison journal={journal} account={account} />
      </DetailsHeader>

      <Stack direction='row' alignItems='center' justifyContent='end'>
        <Search searchCriteria={searchCriteria} onSearchCriteriaUpdate={(searchCriteria) => setSearchCriteria(searchCriteria)} style={{ flex: 1 }} />

        <Tooltip title='Filter Settings'>
          <Badge badgeContent={appliedFilterCount} color='primary' overlap='circular'>
            <IconButton onClick={() => setShowFilterConfigMenu((existing) => !existing)} ref={filterConfigButtonRef}>
              <Filter color={theme.palette.primary.main} />
            </IconButton>
          </Badge>
        </Tooltip>
        <Menu
          open={showFilterConfigMenu}
          onClose={() => setShowFilterConfigMenu(false)}
          anchorEl={filterConfigButtonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Stack
            sx={{
              margin: theme.spacing(5),
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterConfig.onlyShowCurrentEntries}
                  onChange={(e) => setFilterConfig((current) => ({ ...current, onlyShowCurrentEntries: e.target.checked }))}
                />
              }
              label='Only show current entries'
            />
          </Stack>
        </Menu>

        <Button
          style={{
            minWidth: '8rem',
          }}
          variant='contained'
          color='primary'
          onClick={() => setCreateDialogOpen(true)}
        >
          <AddCircle size='1rem' variant='Bold' style={{ marginRight: theme.spacing(2) }} />
          Add entry
        </Button>
        <JournalEntryDialog
          preselectAccount={account}
          journal={journal}
          journalAccountsById={journalAccountsById}
          loading={loading}
          open={createDialogOpen}
          onClose={() => setCreateDialogOpen(false)}
          onCreate={(entry) => createNewEntry(entry)}
        />
        <Button variant='outlined' color='neutral' style={{ background: 'none' }} onClick={() => setDownloadMenuOpen(true)} ref={downloadButtonRef}>
          <Download />
        </Button>
        <Popover
          open={downloadMenuOpen}
          onClose={() => setDownloadMenuOpen(false)}
          anchorEl={downloadButtonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <MenuList>
            <MenuItem
              onClick={() => {
                setDownloadOnlyIncludesCurrentEntries((current) => !current);
              }}
            >
              <FormControlLabel
                onClick={(e) => {
                  e.preventDefault();
                }}
                control={
                  <Checkbox checked={downloadOnlyIncludesCurrentEntries} onChange={(e) => setDownloadOnlyIncludesCurrentEntries(e.target.checked)} />
                }
                label='Only Include Current Entries'
              />
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={async () => {
                await onDownloadAccount({
                  onlyIncludeCurrentEntries: downloadOnlyIncludesCurrentEntries,
                });
              }}
            >
              <ListItemText>Download</ListItemText>
            </MenuItem>
          </MenuList>
        </Popover>
      </Stack>

      <JournalEntryTable
        journal={journal}
        journalEntries={filteredEntries}
        journalAccountsById={journalAccountsById}
        account={account}
        onViewTransactionForJournalEntry={onViewTransactionForJournalEntry}
        onViewDocumentForJournalEntry={onViewDocumentForJournalEntry}
        onSelectJournalEntry={() => updateJournalEntryIds([])}
        onReverseJournalEntry={onReverseJournalEntry}
      />
    </Stack>
  );
}

interface AccountsProps {
  journal: Journal;
  journalEntries: JournalEntry[];
  journalAccountsById: { [accountId: string]: Account };
  onViewTransactionForJournalEntry: (journalEntry: JournalEntry) => void;
  onViewDocumentForJournalEntry: (journalEntry: JournalEntry) => void;
  onCreateJournalEntry: (entry: CreateJournalEntryArgs) => Promise<void>;
  onReverseJournalEntry: (entry: JournalEntry) => Promise<void>;
  onCreateAccount: (createAccountParams: {
    name: string;
    type: string;
    description: string;
    accountNumber?: string;
    parentId?: string;
    currency: string;
  }) => Promise<void>;
  onUpdateAccount: (account: Partial<Account> & { id: string }) => Promise<void>;
  onDeleteAccount: (accountId: string) => Promise<void>;
  onDownloadAccount: (
    accountId: string,
    options?: {
      onlyIncludeCurrentEntries: boolean;
    }
  ) => Promise<void>;
  onDownloadAccounts: (options?: { onlyIncludeCurrentEntries: boolean }) => Promise<void>;
  style?: CSSProperties;
}
function Accounts({
  journal,
  journalEntries,
  journalAccountsById,
  onViewTransactionForJournalEntry,
  onViewDocumentForJournalEntry,
  onCreateJournalEntry,
  onReverseJournalEntry,
  onCreateAccount,
  onUpdateAccount,
  onDeleteAccount,
  onDownloadAccount,
  onDownloadAccounts,
  style,
}: AccountsProps) {
  const [viewAccount, setViewAccount] = useState<Account | null>(null);
  const { accountId, updateAccountId } = useContext(AdminJournalsPageUrlContext);

  useEffect(() => {
    if (!accountId) {
      setViewAccount(null);
    } else if (accountId && journalAccountsById[accountId]) {
      setViewAccount(journalAccountsById[accountId] || null);
    }
  }, [accountId, journalAccountsById]);

  if (viewAccount) {
    return (
      <AccountDetails
        account={viewAccount}
        journal={journal}
        journalEntries={journalEntries}
        journalAccountsById={journalAccountsById}
        onViewTransactionForJournalEntry={onViewTransactionForJournalEntry}
        onViewDocumentForJournalEntry={onViewDocumentForJournalEntry}
        onReverseJournalEntry={onReverseJournalEntry}
        createJournalEntry={onCreateJournalEntry}
        onBack={() => updateAccountId(null)}
        onDownloadAccount={(params) => onDownloadAccount(viewAccount.id, params)}
        style={style}
      />
    );
  } else {
    return (
      <AccountsList
        journal={journal}
        journalEntries={journalEntries}
        journalAccountsById={journalAccountsById}
        onViewAccount={(account) => updateAccountId(account.id)}
        onCreateAccount={onCreateAccount}
        onUpdateAccount={onUpdateAccount}
        onDeleteAccount={onDeleteAccount}
        onDownloadAccounts={onDownloadAccounts}
        style={style}
      />
    );
  }
}

interface AllJournalEntriesProps {
  journal: Journal;
  journalEntries: JournalEntry[];
  journalAccountsById: { [accountId: string]: Account };
  onCreateJournalEntry: (entry: CreateJournalEntryArgs) => Promise<void>;
  onReverseJournalEntry: (entry: JournalEntry) => Promise<void>;
  onViewTransactionForJournalEntry: (journalEntry: JournalEntry) => void;
  onViewDocumentForJournalEntry: (journalEntry: JournalEntry) => void;
  style?: CSSProperties;
}
function AllJournalEntries({
  journal,
  journalEntries,
  journalAccountsById,
  onCreateJournalEntry,
  onViewTransactionForJournalEntry,
  onViewDocumentForJournalEntry,
  onReverseJournalEntry,
  style,
}: AllJournalEntriesProps) {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState<string[]>([]);
  const theme = useTheme();
  const { journalEntryIds, updateJournalEntryIds } = useContext(AdminJournalsPageUrlContext);

  const [filterConfig, setFilterConfig] = useState({
    onlyShowCurrentEntries: true,
  });
  const appliedFilterCount = useMemo(() => {
    let count = 0;
    if (!filterConfig.onlyShowCurrentEntries) {
      count++;
    }
    return count;
  }, [filterConfig]);
  const [showFilterConfigMenu, setShowFilterConfigMenu] = useState(false);
  const filterConfigButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (journalEntryIds.length) {
      setSearchCriteria(journalEntryIds.map((eid) => `id::${eid}`));
    }
  }, [journalEntryIds]);

  const [loading, setLoading] = useState(false);
  const createNewEntry = async (entry: CreateJournalEntryArgs) => {
    try {
      setLoading(true);
      await onCreateJournalEntry(entry);
      setCreateDialogOpen(false);
    } finally {
      setLoading(false);
    }
  };

  const filteredJournalEntries = useFilteredJournalEntries({
    journalEntries,
    searchCriteria,
    journalAccountsById,
    filterConfig,
  });

  if (!filteredJournalEntries) {
    return (
      <Box alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack style={style}>
      <Stack direction='row' alignItems='center'>
        <Search searchCriteria={searchCriteria} onSearchCriteriaUpdate={(searchCriteria) => setSearchCriteria(searchCriteria)} style={{ flex: 1 }} />

        <Tooltip title='Filter Settings'>
          <Badge badgeContent={appliedFilterCount} color='primary' overlap='circular'>
            <IconButton onClick={() => setShowFilterConfigMenu((existing) => !existing)} ref={filterConfigButtonRef}>
              <Filter color={theme.palette.primary.main} />
            </IconButton>
          </Badge>
        </Tooltip>
        <Menu
          open={showFilterConfigMenu}
          onClose={() => setShowFilterConfigMenu(false)}
          anchorEl={filterConfigButtonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Stack
            sx={{
              margin: theme.spacing(5),
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterConfig.onlyShowCurrentEntries}
                  onChange={(e) => setFilterConfig((current) => ({ ...current, onlyShowCurrentEntries: e.target.checked }))}
                />
              }
              label='Only show current entries'
            />
          </Stack>
        </Menu>

        <Button
          style={{
            minWidth: '8rem',
          }}
          variant='contained'
          color='primary'
          onClick={() => setCreateDialogOpen(true)}
        >
          <AddCircle size='1rem' variant='Bold' style={{ marginRight: theme.spacing(2) }} />
          Add entry
        </Button>
        <JournalEntryDialog
          journal={journal}
          journalAccountsById={journalAccountsById}
          loading={loading}
          open={createDialogOpen}
          onClose={() => setCreateDialogOpen(false)}
          onCreate={(entry) => createNewEntry(entry)}
        />
      </Stack>

      <JournalEntryTable
        journal={journal}
        journalEntries={filteredJournalEntries}
        journalAccountsById={journalAccountsById}
        onSelectJournalEntry={() => updateJournalEntryIds([])}
        onViewTransactionForJournalEntry={onViewTransactionForJournalEntry}
        onViewDocumentForJournalEntry={onViewDocumentForJournalEntry}
        onReverseJournalEntry={onReverseJournalEntry}
      />
    </Stack>
  );
}

interface TrialBalanceTableProps {
  organization: Organization;
  trialBalance: TrialBalance;
  selectedJournal: Journal;
  journals: Journal[];
  journalAccountsById: { [accountId: string]: Account };
  onDownload: () => Promise<void>;
  onDownloadFinancialStatements: (journalId: string, type: string, period?: number) => Promise<void>;
  style?: CSSProperties;
}
function TrialBalanceTable({
  organization,
  trialBalance,
  selectedJournal,
  journals,
  journalAccountsById,
  onDownload,
  onDownloadFinancialStatements,
  style,
}: TrialBalanceTableProps) {
  const theme = useTheme();
  const { updateAccountId } = useContext(AdminJournalsPageUrlContext);

  const [searchCriteria, setSearchCriteria] = useState<string[]>([]);

  const viewAccount = (accountId: string) => {
    updateAccountId(journalAccountsById[accountId].id || null);
  };

  const balanced = trialBalance.total.credit === trialBalance.total.debit;

  const [showFilterConfig, setShowFilterConfig] = useState(false);
  const filterConfigButtonRef = useRef<HTMLButtonElement | null>(null);
  const [filterConfig, setFilterConfig] = useState({
    includeZeroBalanceRows: false,
  });
  const appliedFilterCount = useMemo(() => {
    let count = 0;
    if (filterConfig.includeZeroBalanceRows) {
      count++;
    }
    return count;
  }, [filterConfig]);

  const [showDownloadMenu, setShowDownloadMenu] = useState(false);
  const [showFinancialStatementDialog, setShowFinancialStatementDialog] = useState(false);
  const downloadButtonRef = useRef<HTMLButtonElement | null>(null);

  const [openRows, setOpenRows] = useState<Record<string, boolean>>({});

  const toggleOpenRow = useCallback((id: string) => {
    setOpenRows((existing) => ({
      ...existing,
      [id]: !existing[id],
    }));
  }, []);

  const treeRows = useMemo(() => {
    const accountRows = trialBalance.accountBalances.map((balance) => ({
      id: balance.accountId,
      parentId: journalAccountsById[balance.accountId]?.parentId,
      path: journalAccountsById[balance.accountId]?.path || '',
      account: balance.accountName,
      standaloneDebit: balance.standaloneDebit,
      standaloneCredit: balance.standaloneCredit,
      consolidatedDebit: balance.consolidatedDebit,
      consolidatedCredit: balance.consolidatedCredit,
    }));

    const treeOrdered = SortIntoTreeOrder(accountRows, (a, b) => a.account.localeCompare(b.account));

    return [
      {
        id: 'TOTAL',
        account: 'TOTAL',
        parentId: null,
        path: '',
        standaloneDebit: trialBalance.total.debit,
        standaloneCredit: trialBalance.total.credit,
        consolidatedDebit: trialBalance.total.debit,
        consolidatedCredit: trialBalance.total.credit,
      },
      ...treeOrdered,
    ];
  }, [trialBalance, journalAccountsById]);

  const searchRows = useMemo(() => {
    const rows = new Set<string>();
    for (const treeRow of treeRows) {
      for (const searchCriterion of searchCriteria) {
        if (
          searchCriterion.toLowerCase().includes(treeRow.account.toLowerCase()) ||
          treeRow.account.toLowerCase().includes(searchCriterion.toLowerCase())
        ) {
          rows.add(treeRow.id);
          for (const ancestor of treeRow.path.split('/')) {
            rows.add(ancestor);
          }
        }
      }
    }

    return rows;
  }, [treeRows, searchCriteria]);

  const rowsShowing = useMemo(() => {
    const rowsToShow = new Set<string>();
    for (const treeRow of treeRows) {
      if (treeRow.id === 'TOTAL') {
        rowsToShow.add(treeRow.id);
      } else if (
        !treeRow.parentId &&
        !searchCriteria.length &&
        (filterConfig.includeZeroBalanceRows ||
          (treeRow.consolidatedCredit && treeRow.consolidatedCredit !== '0') ||
          (treeRow.consolidatedDebit && treeRow.consolidatedDebit !== '0'))
      ) {
        rowsToShow.add(treeRow.id);
      } else if (searchRows.has(treeRow.id)) {
        rowsToShow.add(treeRow.id);
      } else {
        let ancestorsOpen = true;
        for (const ancestor of treeRow.path.split('/')) {
          if (!openRows[ancestor]) {
            ancestorsOpen = false;
          }
        }
        if (ancestorsOpen) {
          rowsToShow.add(treeRow.id);
        }
      }
    }

    return treeRows.filter((r) => rowsToShow.has(r.id));
  }, [treeRows, openRows, searchRows, searchCriteria, filterConfig]);

  const columns: GridColDef[] = [
    {
      field: 'treeToggle',
      headerName: '',
      width: 64,
      sortable: false,
      renderCell: (params) => {
        const account = params.row as Account & TreeEntity;
        if (account.hasChildren) {
          return (
            <IconButton disabled={searchRows.has(account.id)} onClick={() => toggleOpenRow(account.id)} sx={{ marginRight: 8 }}>
              {(openRows[account.id] || searchRows.has(account.id)) && <ArrowDown2 />}
              {!openRows[account.id] && !searchRows.has(account.id) && <ArrowRight2 />}
            </IconButton>
          );
        }
      },
    },
    {
      field: 'account',
      headerName: 'Account',
      flex: 1,
      renderCell: (params) => {
        const row = params.row as { id: string; account: string; debit: string | null; credit: string | null } & TreeEntity;
        const isTotalRow = row.id === 'TOTAL';
        let bgColor: string | undefined;
        if (isTotalRow) {
          if (balanced) {
            bgColor = theme.palette.primary[200];
          } else {
            bgColor = theme.palette.error.light;
          }
        } else {
          bgColor = undefined;
        }

        return (
          <Box
            bgcolor={bgColor}
            padding={theme.spacing(2)}
            paddingLeft={theme.spacing(4 * row.depth)}
            color={isTotalRow ? theme.palette.common.black : undefined}
          >
            {isTotalRow ? <strong>TOTAL</strong> : <>{row.account}</>}
          </Box>
        );
      },
    },
    {
      field: 'debit',
      headerName: 'Debit',
      headerAlign: 'right',
      align: 'right',
      width: 200,
      renderCell: (params) => {
        const row = params.row as {
          id: string;
          account: string;
          standaloneDebit: string | null;
          standaloneCredit: string | null;
          consolidatedDebit: string | null;
          consolidatedCredit: string | null;
        };
        const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' });
        const renderedAmount =
          !row.consolidatedDebit || openRows[row.id] || (searchRows.has(row.id) && (!row.standaloneDebit || row.standaloneDebit === '0'))
            ? ''
            : amountFormatter.format(parseFloat(row.consolidatedDebit));
        const isTotalRow = row.id === 'TOTAL';
        let bgColor: string | undefined;
        if (isTotalRow) {
          if (balanced) {
            bgColor = theme.palette.primary[200];
          } else {
            bgColor = theme.palette.error.light;
          }
        } else {
          bgColor = undefined;
        }

        return (
          <Box bgcolor={bgColor} padding={theme.spacing(2)} color={isTotalRow ? theme.palette.common.black : undefined}>
            {isTotalRow ? <strong>{renderedAmount}</strong> : <>{renderedAmount}</>}
          </Box>
        );
      },
    },
    {
      field: 'credit',
      headerName: 'Credit',
      headerAlign: 'right',
      align: 'right',
      width: 200,
      renderCell: (params) => {
        const row = params.row as {
          id: string;
          account: string;
          standaloneDebit: string | null;
          standaloneCredit: string | null;
          consolidatedDebit: string | null;
          consolidatedCredit: string | null;
        };
        const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' });
        const renderedAmount =
          !row.consolidatedCredit ||
          row.consolidatedCredit === '0' ||
          openRows[row.id] ||
          (searchRows.has(row.id) && (!row.standaloneCredit || row.standaloneCredit === '0'))
            ? ''
            : amountFormatter.format(parseFloat(row.consolidatedCredit));
        const isTotalRow = row.id === 'TOTAL';
        let bgColor: string | undefined;
        if (isTotalRow) {
          if (balanced) {
            bgColor = theme.palette.primary[200];
          } else {
            bgColor = theme.palette.error.light;
          }
        } else {
          bgColor = undefined;
        }

        return (
          <Box bgcolor={bgColor} padding={theme.spacing(2)} color={isTotalRow ? theme.palette.common.black : undefined}>
            {isTotalRow ? <strong>{renderedAmount}</strong> : <>{renderedAmount}</>}
          </Box>
        );
      },
    },
    {
      field: 'viewAccount',
      headerName: 'View Account',
      renderCell: (params) => {
        const row = params.row as {
          id: string;
          account: string;
          standaloneDebit: string | null;
          standaloneCredit: string | null;
          consolidatedDebit: string | null;
          consolidatedCredit: string | null;
        };

        return row.id === 'TOTAL' ? null : (
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              viewAccount(row.id);
            }}
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <Stack style={style}>
      <Stack direction='row'>
        <Search searchCriteria={searchCriteria} onSearchCriteriaUpdate={(criteria) => setSearchCriteria(criteria)} style={{ flex: 1 }} />
        <Tooltip title='Filter Settings'>
          <Badge badgeContent={appliedFilterCount} color='primary' overlap='circular'>
            <IconButton onClick={() => setShowFilterConfig((existing) => !existing)} ref={filterConfigButtonRef}>
              <Filter color={theme.palette.primary.main} />
            </IconButton>
          </Badge>
        </Tooltip>
        <Menu
          open={showFilterConfig}
          onClose={() => setShowFilterConfig(false)}
          anchorEl={filterConfigButtonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Stack
            sx={{
              margin: theme.spacing(5),
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterConfig.includeZeroBalanceRows}
                  onChange={(e) => setFilterConfig((current) => ({ ...current, includeZeroBalanceRows: e.target.checked }))}
                />
              }
              label='Include Zero Balance Rows'
            />
          </Stack>
        </Menu>

        <Button ref={downloadButtonRef} variant='outlined' color='neutral' style={{ background: 'none' }} onClick={() => setShowDownloadMenu(true)}>
          <Download />
        </Button>
        <Popover
          open={showDownloadMenu}
          onClose={() => setShowDownloadMenu(false)}
          anchorEl={downloadButtonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <MenuList>
            <MenuItem
              onClick={async () => {
                await onDownload();
              }}
            >
              <ListItemText>Download Trial Balance CSV</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => setShowFinancialStatementDialog(true)}>
              <ListItemText>Download PDF Financial Statements</ListItemText>
            </MenuItem>
          </MenuList>
        </Popover>
        <DownloadFinancialStatementDialog
          organization={organization}
          open={showFinancialStatementDialog}
          onClose={() => setShowFinancialStatementDialog(false)}
          journals={journals}
          selectedJournal={selectedJournal}
          onDownloadFinancialStatements={onDownloadFinancialStatements}
        />
      </Stack>
      <DataGrid
        rows={rowsShowing}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100]}
      />
    </Stack>
  );
}

const AdminJournalsPageUrlContext = createContext(
  {} as {
    accountId: string | null;
    journalEntryIds: string[];
    updateJournalEntryIds: (transactionIds: string[]) => void;
    updateAccountId: (accountId: string | null) => void;
  }
);

const AdminJournalsPageUrlContextProvider = ({ children }: { children: React.ReactNode }) => {
  const JOURNAL_ENTRY_IDS_PARAM = 'journalEntryIds';
  const ACCOUNT_ID_PARAM = 'accountId';

  const location = useLocation();
  const searchParams = useRef(new URLSearchParams(location.search));
  const [accountId, setAccountId] = useState<string | null>(null);
  const [journalEntryIds, setJournalEntryIds] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    searchParams.current = new URLSearchParams(location.search);
  }, [location]);

  const updateSearchParams = useCallback(
    (params: URLSearchParams) => {
      searchParams.current = params;
      navigate({
        search: params.toString(),
      });
    },
    [navigate]
  );

  useEffect(() => {
    const queryParams = searchParams.current;

    const accountIdParam = queryParams.get(ACCOUNT_ID_PARAM);
    if (accountIdParam) {
      setAccountId(accountIdParam);
    }

    const journalEntryIds = queryParams.getAll(JOURNAL_ENTRY_IDS_PARAM);
    if (journalEntryIds) {
      setJournalEntryIds(journalEntryIds);
    }
  }, [location]);

  const updateJournalEntryIds = useCallback(
    (journalEntryIds: string[]) => {
      if (!journalEntryIds.length) {
        const params = searchParams.current;

        params.delete(JOURNAL_ENTRY_IDS_PARAM);

        updateSearchParams(params);
      } else {
        const params = searchParams.current;

        params.delete(JOURNAL_ENTRY_IDS_PARAM);
        for (const id of journalEntryIds) {
          params.append(JOURNAL_ENTRY_IDS_PARAM, id);
        }

        updateSearchParams(params);
      }

      setJournalEntryIds(journalEntryIds);
    },
    [updateSearchParams, searchParams]
  );

  const updateAccountId = useCallback(
    (accountId: string | null) => {
      if (!accountId) {
        const params = searchParams.current;

        params.delete(ACCOUNT_ID_PARAM);

        updateSearchParams(params);
      } else {
        const params = searchParams.current;

        params.delete(ACCOUNT_ID_PARAM);
        params.set(ACCOUNT_ID_PARAM, accountId);

        updateSearchParams(params);
      }

      setAccountId(accountId);
    },
    [searchParams, updateSearchParams]
  );

  return (
    <AdminJournalsPageUrlContext.Provider value={{ accountId, journalEntryIds, updateJournalEntryIds, updateAccountId }}>
      {children}
    </AdminJournalsPageUrlContext.Provider>
  );
};

interface AdminJournalPageTabsProps {
  currentTab: AdminJournalPageTab;
  onChangeTab: (tab: AdminJournalPageTab) => void;
}
function AdminJournalPageTabs({ currentTab, onChangeTab }: AdminJournalPageTabsProps) {
  const { accountId, journalEntryIds, updateAccountId, updateJournalEntryIds } = useContext(AdminJournalsPageUrlContext);

  useEffect(() => {
    if (accountId) {
      onChangeTab(AdminJournalPageTab.ACCOUNTS);
    } else if (journalEntryIds.length) {
      onChangeTab(AdminJournalPageTab.ALL_ENTRIES);
    }
  }, [accountId, journalEntryIds, onChangeTab]);

  return (
    <Tabs
      value={currentTab}
      onChange={(_e, newValue: AdminJournalPageTab) => {
        if (newValue !== AdminJournalPageTab.ACCOUNTS) {
          updateAccountId(null);
        }
        if (newValue !== AdminJournalPageTab.ALL_ENTRIES) {
          updateJournalEntryIds([]);
        }
        onChangeTab(newValue);
      }}
    >
      <Tab label={AdminJournalPageTab.TRIAL_BALANCE} value={AdminJournalPageTab.TRIAL_BALANCE} />
      <Tab label={AdminJournalPageTab.ALL_ENTRIES} value={AdminJournalPageTab.ALL_ENTRIES} />
      <Tab label={AdminJournalPageTab.ACCOUNTS} value={AdminJournalPageTab.ACCOUNTS} />
    </Tabs>
  );
}

enum AdminJournalPageTab {
  TRIAL_BALANCE = 'Trial Balance',
  ALL_ENTRIES = 'All Journal Entries',
  ACCOUNTS = 'Accounts',
}

export function AdminJournalPage({ ...props }) {
  const { selectedOrganization: selectedOrg, setSelectedOrganization: setSelectedOrg, previousSelectedOrganization } = useSelectedOrganization(null);
  const [selectedJournal, setSelectedJournal] = useState<Journal | null>(null);
  const {
    journals,
    journalEntries,
    journalAccountsById,
    organizations,
    createJournalEntry,
    reverseJournalEntry,
    trialBalance,
    createJournalAccount,
    updateJournalAccount,
    deleteJournalAccount,
    downloadAccount,
    downloadAccounts,
    downloadTrialBalance,
    downloadFinancialStatements,
  } = useAdminData(selectedOrg, selectedJournal);
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState(AdminJournalPageTab.TRIAL_BALANCE);

  const viewTransaction = useCallback((journalEntry: JournalEntry) => {
    const transactionTag = journalEntry.tags.find((t) => t.startsWith('transaction::'));
    if (!transactionTag) {
      return;
    }

    const transactionId = transactionTag.split('::')[1];

    if (transactionId) {
      window.open(`/admin/transactions?tab=REVIEWED&transactionIds=${transactionId}`, '_blank');
    }
  }, []);

  const viewDocument = useCallback((journalEntry: JournalEntry) => {
    const documentTag = journalEntry.tags.find((t) => t.startsWith('document::'));
    if (!documentTag) {
      return;
    }

    const documentId = documentTag.split('::')[1];

    if (documentId) {
      window.open(`/admin/documents?tab=REVIEWED&documentIds=${documentId}`, '_blank');
    }
  }, []);

  if (!journals || !journalEntries || !organizations || !journalAccountsById) {
    return (
      <PageContainer {...props}>
        <PageHeader title='Admin - Journals' />
        <PageBody gutter='thin' style={{ alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </PageBody>
      </PageContainer>
    );
  }

  return (
    <AdminJournalsPageUrlContextProvider>
      <PageContainer {...props}>
        <PageHeader title='Admin - Journals' />
        <PageBody gutter='thin'>
          <Stack height='100%'>
            <Stack direction='row' paddingTop={theme.spacing(2)}>
              <AdminOrganizationSelect
                organizations={organizations}
                onOrganizationChange={(org) => {
                  setSelectedOrg(org);
                  if (selectedOrg?.id !== previousSelectedOrganization?.id) {
                    setSelectedJournal(null);
                  }
                }}
              />

              <AdminJournalSelect
                journals={journals}
                organization={selectedOrg}
                selectedJournal={selectedJournal}
                onJournalChange={(j) => setSelectedJournal(j)}
              />
            </Stack>

            {selectedOrg && selectedJournal && (
              <>
                <AdminJournalPageTabs currentTab={currentTab} onChangeTab={setCurrentTab} />

                {currentTab === AdminJournalPageTab.TRIAL_BALANCE && trialBalance && (
                  <TrialBalanceTable
                    organization={selectedOrg}
                    journals={journals}
                    selectedJournal={selectedJournal}
                    journalAccountsById={journalAccountsById}
                    trialBalance={trialBalance}
                    onDownload={downloadTrialBalance}
                    onDownloadFinancialStatements={downloadFinancialStatements}
                    style={{ flex: 1, overflow: 'auto' }}
                  />
                )}
                {currentTab === AdminJournalPageTab.ALL_ENTRIES && (
                  <AllJournalEntries
                    style={{ flex: 1, overflow: 'auto' }}
                    journal={selectedJournal}
                    journalEntries={journalEntries}
                    journalAccountsById={journalAccountsById}
                    onCreateJournalEntry={(entry) => createJournalEntry(selectedJournal.id, entry)}
                    onReverseJournalEntry={(entry) => reverseJournalEntry(entry.id)}
                    onViewTransactionForJournalEntry={viewTransaction}
                    onViewDocumentForJournalEntry={viewDocument}
                  />
                )}
                {currentTab === AdminJournalPageTab.ACCOUNTS && (
                  <Accounts
                    style={{ flex: 1, overflow: 'auto' }}
                    journal={selectedJournal}
                    journalEntries={journalEntries}
                    journalAccountsById={journalAccountsById}
                    onCreateJournalEntry={(entry) => createJournalEntry(selectedJournal.id, entry)}
                    onReverseJournalEntry={(entry) => reverseJournalEntry(entry.id)}
                    onViewTransactionForJournalEntry={viewTransaction}
                    onViewDocumentForJournalEntry={viewDocument}
                    onCreateAccount={createJournalAccount}
                    onUpdateAccount={updateJournalAccount}
                    onDeleteAccount={deleteJournalAccount}
                    onDownloadAccount={downloadAccount}
                    onDownloadAccounts={downloadAccounts}
                  />
                )}
              </>
            )}
          </Stack>
        </PageBody>
      </PageContainer>
    </AdminJournalsPageUrlContextProvider>
  );
}
